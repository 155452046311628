import request from '@/utils/request'

/**
 * 获取班级列表
 * @param {*} params 
 * @returns 
 */
 export function getClassList(params) {
  return request({
    url: 'edu.school/getClassList',
    method: 'get',
    params
  })
}
/**
 * 发送课程到班级
 * @param {*} data 
 * @returns 
 */
 export function sendCourseToStudent(data) {
  return request({
    url: 'xinao.backage/sendCourseToStudent',
    method: 'post',
    data
  })
}
/**
 * 获取班级学生的当前章节的学习状态
 * @param {*} params 
 * @returns 
 */
 export function getSectionStudyStatus(params) {
  return request({
    url: 'xinao.backage/getSectionStudyStatus',
    method: 'get',
    params
  })
}

/**
 * 获取班级学生的当前章节的学习状态
 * @param {*} params 
 * @returns 
 */
 export function getChapterStudyStatus(params) {
  return request({
    url: 'xinao.backage/getChapterStudyStatus',
    method: 'get',
    params
  })
}

/**
 * 获取班级学生的完成代码
 * @param {*} params 
 * @returns 
 */
export function getStudentFinishCode(params) {
  return request({
    url: 'xinao.backage/getStudentFinishCode',
    method: 'get',
    params
  })
}